import React, { useState, useEffect } from "react";
import "./certificateLogsList.scss";
import search from "../../assets/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getcertlogssearchList,
  refetchTransactions,
  updateTransactions,
  getcertlogssearchListByOrg
} from "../../Redux/Actions/CertificateLogs";
import NewFlash from "../FlashMessage/NewFlash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import TransactionModal from "./transactionModal";
import UpdateTransactionModal from "./updateTransactionModal";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getOrganizationAccountList } from "../../Redux/Actions/OrganizationManagement";
// import EditModal from "../EditModal/EditModal";
import CertificateSearchModal from "../CertificateSearchModal/certificateSearchModal";
import Select from "react-select";
import ReactSelect from "../ReactSelect/ReactSelect";
import _ from "lodash"
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';



const CertificateLogsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState(false);
  const [bank, setBank] = useState("");
  const [connection, setConnection] = useState({});
  const [transaction, setTransactions] = useState(0);
  const [request, setRequest] = useState({});
  const [event_logs, setEventLogs] = useState([]);
  const [organization_user, setOrganizationUser] = useState([]);
  const [organization, setOrganization] = useState({});
  const [errorLogs, setErrorLogs] = useState([]);
  const consent_credentials = useSelector(
    (state) => state.consent_credentials.consent_credentials
  );
  const userId = consent_credentials.userId;
  const companyId = consent_credentials.companyId;
  const [caseidVisibility, setCaseidVisibility] = useState(false);
  const [bankdataVisibility, setBankdataVisibility] = useState(false);
  const [tokenVisibility, setTokenVisibility] = useState(false);
  const [credentialsVisibility, setCredentialsVisibility] = useState(false);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({
    iconImage: "",
    firstMsg: "",
    secondMsg: "",
  });
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showUpdateTransactionModal, setShowUpdateTransactionModal] =
    useState(false);
  const [newTransactionCount, setNewTransactionCount] = useState(0);
  const [certificateUnable, setCertificateUnable] = useState(false);
  const [browserDetails, setBrowserDetails] = useState({})
  const [userPrefer, setUserPrefer] = useState({})
  const [keysWithTrueValues, setKeysWithTrueValues] = useState("")
  const [organizationList, setOrganizationList] = useState([])
  const [selectedOrg, setSelectedOrg] = useState({})
  const [openPopUp, setOpenPopUp] = useState(false)
  const [matchedData, setMatchedData] = useState([])
  const [enablePartial, setEnablePartial] = useState(false)
  const [clientReference, setClientReference] = useState()
  const [selectedOrgSelect, setSelectedOrgSelect] = useState()
  const [organizationListData, setOrganizationListData] = useState()
  const [memberRequestBank, setMemberRequestBank] = useState([])
  const [openRows, setOpenRows] = useState(new Set());
  const [callbackEvent, setCallbackEvent] = useState({})

  const toggleRow = (index) => {
    const newOpenRows = new Set(openRows);
    if (newOpenRows.has(index)) {
      newOpenRows.delete(index);
    } else {
      newOpenRows.add(index);
    }
    setOpenRows(newOpenRows);
  };
  const [refetchDays, setRefetchDays] = useState()
  const [refetchDaysArr, setRefetchDaysArr] = useState([])

  const closeFlash = () => {
    setFlashMessage(false);
  };

  const SearchCertID = (e) => {
    e.preventDefault();
    const searchKey = e.target.value;
    setSearchKey(searchKey);
  };

  const openFlashFn = async (message) => {
    await dispatch(setLoaderSpinner(false));
    setFlashMessage(true);
    setFlashData((prevState) => ({
      ...prevState,
      iconImage: "",
      firstMsg: "Error",
      secondMsg: message,
    }));
  }

  const searchList = async (e) => {
    e.preventDefault();
    await dispatch(setLoaderSpinner(true));
    let selectedOrganization = selectedOrg.org_options === undefined ? "" : selectedOrg.org_options
    let query = '?id=' + selectedOrganization + '&partial_client_reference_id=' + searchKey;
    if ((userPrefer.search_options === "partial_client_reference_id" && selectedOrg.org_options === undefined) || (userPrefer.search_options === "partial_client_reference_id" && selectedOrg.org_options === '')) {
      openFlashFn("Please select any organization for partial client reference id")
    } else if ((userPrefer.search_options === undefined && searchKey === "") || (userPrefer.search_options === "" && searchKey === "")) {
      openFlashFn("Please enter both search by and id")
    } else if ((userPrefer.search_options === undefined) || (userPrefer.search_options === "")) {
      openFlashFn("Please enter select one option of search by")
    } else if (searchKey === "") {
      openFlashFn("Please enter id for search")
    } else {
      if (userPrefer.search_options === "partial_client_reference_id") {
        setClientReference(false)
        const { data } = await dispatch(getcertlogssearchListByOrg(query))
        if (data?.length === 0) {
          openFlashFn("Request not found with this organization id")
        } else {
          setMatchedData(data)
          setOpenPopUp(true)
        }
      } else {
        selectedCertificateHandler()
      }
    }
    await dispatch(setLoaderSpinner(false));
  };

  const selectedCertificateHandler = async (id) => {
    let query;
    let selectedOrganization = selectedOrg.org_options === undefined ? "" : selectedOrg.org_options
    if (id) {
      query = `?find_by_param=id&find_by_value=${id}&organization_id=${selectedOrganization}`
    } else {
      query = `?find_by_param=${userPrefer.search_options}&find_by_value=${searchKey}&organization_id=${selectedOrganization}`
    }
    const {
      status,
      message,
      data = null,
    } = await dispatch(getcertlogssearchList(query));
    if (status) {
      const { refetch_days, fetch_days } = data;
      function generateArray(num) {
        let result = [];
        let step = 30;

        for (let i = step; i < num; i += step) {
          result.push(i);
        }

        result.push(num);
        return result;
      }

      let array;
      if (fetch_days < refetch_days) {
        array = generateArray(fetch_days);
      } else {
        array = generateArray(refetch_days);
      }
      setRefetchDaysArr(array)
      setRefetchDays(data?.refetch_days)
      setOpenPopUp(false)
      if (Array.isArray(data)) {
        setClientReference(true)
        setMatchedData(data)
        setOpenPopUp(true)
      } else {
        setStatus(message);
        setCertificateUnable(true)
        setCallbackEvent(data?.callback_and_event_tracking)
        setRequest(data.request);
        setTransactions(data.transaction);
        setBank(data.bank);
        setConnection(data.connection);
        let organizationUserData = data.organization_user;

        if (organizationUserData.manual_request) {
          setKeysWithTrueValues("manual_request")
        } else if (organizationUserData.api) {
          setKeysWithTrueValues("api")
        } else if (organizationUserData.iframe) {
          setKeysWithTrueValues("iframe")
        } else if (organizationUserData.external_payload) {
          setKeysWithTrueValues("external_payload")
        }

        setMemberRequestBank(data?.member_request_banks)
        setOrganizationUser(data.organization_user);
        setOrganization(data.organization);
        setErrorLogs(data.error_logs);
        const sortedData = [...data.event_logs, ...data.admin_logs].sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        )
        const groups = _.groupBy(sortedData, 'event_type')
        const arr = Object.values(groups)
        setEventLogs(arr)
        setBrowserDetails(data?.browser_history)
      }
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      } else {
        openFlashFn(message)
      }
    }
  }


  const handleToggleCaseidVisibility = () => {
    setCaseidVisibility(!caseidVisibility);
  };
  const handleToggleBankdataVisibility = () => {
    setBankdataVisibility(!bankdataVisibility);
  };
  const handleToggleTokenVisibility = () => {
    setTokenVisibility(!tokenVisibility);
  };
  const handleCredentialsVisibility = () => {
    setCredentialsVisibility(!credentialsVisibility);
  };

  const customeEventType = (item) => {
    let customEventTypeObj = {
      "certificate_created": "Request Sent",
      "bank_flow_completed": "Certificate Created",
      "link_visited_cellphone": "Link Visited",
      "update_certificate_button_clicked": `User: - ${item.user_name}`,
      "refetch_transactions_button_clicked": `User: - ${item.user_name}`,
      "banks_selected": `${item.event_type.replaceAll("_", " ")}${item.display_name ? `-${item.display_name}` : ''}`,
      "bank_data_connected": `${item.event_type.replaceAll("_", " ")}${item.display_name ? `-${item.display_name}` : ''}`,
      "bank_removed": `${item.event_type.replaceAll("_", " ")}${item.display_name ? `-${item.display_name}` : ''}`,
      "bank_flow_started": `${item.event_type.replaceAll("_", " ")}${item.display_name ? `-${item.display_name}` : ''}`
    }

    return customEventTypeObj[item.event_type] || item.event_type.replaceAll('_', ' ');
  };

  const renderErrorLogs = errorLogs.map((item) => (
    <tr key={Math.random()}>
      <td className="email_certlogs">{item.id}</td>
      <td className="email_certlogs">{moment(item.created_at).format("D MMM, YYYY h:mm:ss A")}</td>
      <td className="td_certlogs">{item.organization_request_id}</td>
      <td className="td_toggle_certlogs">{item.error_type}</td>
      <td className="email_certlogs lowercase">
        {item?.message?.replaceAll("_", " ")}
      </td>
      <td className="td_certlogs">{item.error_message}</td>
      <td className="td_certlogs">{item.data}</td>
      <td className="td_certlogs">{item.service_id}</td>
    </tr>
  ));

  // refetech Transactions
  const fetchTransaction = async (id) => {
    setShowTransactionModal(false);
    await dispatch(setLoaderSpinner(true));
    const { status, message, data } = await dispatch(refetchTransactions(id, refetchDays));
    await dispatch(setLoaderSpinner(false));
    if (status) {
      setNewTransactionCount(data.new_transactions);
      setShowUpdateTransactionModal(true);
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      } else {
        openFlashFn(message)
      }
    }
  };

  const updateTransaction = async (id, e) => {
    setShowUpdateTransactionModal(false);
    await dispatch(setLoaderSpinner(true));
    const { status, message } = await dispatch(updateTransactions(id));
    await dispatch(setLoaderSpinner(false));
    if (status) {
      setFlashMessage(true);
      setFlashData((prevState) => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message,
      }));
      searchList(e);
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      } else {
        openFlashFn(message)
      }
    }
  };


  const updatetransactionModalwithupdateResponse = async (value, e) => {
    setShowUpdateTransactionModal(value);
    searchList(e);
  };

  const handleSelectSearch = (e) => {
    const { name, value } = e.target;
    setUserPrefer(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const keysToName = (key) => {
    switch (key) {
      case "api":
        return "API Request"
      case "manual_request":
        return "Manual Request"
      case "iframe":
        return "Iframe Request"
      case "external_payload":
        return "External Payload Request"
      default:
        return "Generic request";
    }
  }

  const handleSelectOrg = (selected) => {
    setSelectedOrgSelect(selected)
    const { value } = selected;
    value === "" ? setEnablePartial(false) : setEnablePartial(true)
    setSelectedOrg(prevState => ({
      ...prevState,
      "org_options": value
    }))
  }

  const fetchOrgList = async () => {
    let query = "?include_all=" + true
    await dispatch(setLoaderSpinner(true))
    const response = await dispatch(getOrganizationAccountList(query))
    let newListOne = [{ "value": "", "label": "Select Client" }]
    setOrganizationListData(response.data.organization_list)
    let newList = response.data.organization_list.map(org => ({ "value": org.id, "label": `${org.name.charAt(0).toUpperCase() + org.name.slice(1)} (${org.id})` }))
    newListOne = [...newListOne, ...newList]
    setOrganizationList(newListOne)
    await dispatch(setLoaderSpinner(false))
  }

  useEffect(() => {
    fetchOrgList()
  }, [])


  const togglePopup = () => {
    setOpenPopUp(false)
  }


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#2e70d0' : 'white',
      color: state.isSelected ? 'white !important' : '#5B6A83',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: '#fff',
      },
    }),
  };

  const showEventType = (data) => {
    let eventTypeName = {
      "bank_flow_completed": "Bank flow completed by end user",
      "bank_data_connected": `${data.display_name ? data.display_name : ""} data connected`,
      "change_in_status": `Status ${data?.column_affected?.status ? data?.column_affected?.status[0] : ""} changed to
      ${data?.column_affected?.status ? data?.column_affected?.status[1] : ""}`,
      "continue_authentication_clicked": `End user clicked "Continue Authentication" button`,
      "banks_selected": `${data.display_name ? data.display_name : ""}`,
      "link_visited": "Link Visited by Email",
      "link_visited_cellphone": "Link Visited by SMS",
      "certificate_created": `${data.certificate_request_sent_by_email_or_sms ? data.certificate_request_sent_by_email_or_sms : ""}`,
      "bank_flow_started": `Fetching data from ${data.display_name ? data.display_name : ""} `,
      "refetch_transactions_button_clicked": `Refetch data from ${data?.column_affected?.bank_name} `,
      "update_certificate_button_clicked": "Updating certificate with refetched transactions",
      "bank_removed": `${JSON.stringify(data.column_affected)} `
    }
    return eventTypeName[data?.event_type] || '';
  }
  
  const handleRefetchDays = (e) => {
    setRefetchDays(e.target.value)
  }

  function formatDate(value) {
    if (moment(value, moment.ISO_8601, true).isValid()) {
      return moment(value).format("DD MMM, YYYY h:mm:ss A");
    }
    return value;
  }

  function processData(input) {
    let newData = ({ ...input })
    const result = {};
    for (const [key, value] of Object.entries(newData)) {
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        result[key] = "N/A";
      } else if (Array.isArray(value)) {
        result[key] = value.map(formatDate).join(", ");
      } else if (moment(value, moment.ISO_8601, true).isValid()) {
        result[key] = formatDate(value)
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  return (
    <div className="page-container">
      {flashMessage && (
        <div
          className={`alert - popup alert - success - bar ${flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) & "alert-shown"
            : "alert-hidden"
            } `}
        >
          <NewFlash data={flashData} closeFlash={() => closeFlash()} />
        </div>
      )}

      {
        openPopUp && <CertificateSearchModal
          clientReference={clientReference}
          firstTextUser={""}
          secondTextUser={organization_user.id}
          matchedData={matchedData}
          confirm={selectedCertificateHandler}
          closeDelete={togglePopup}
        />
      }

      <div className="request-container">
        <h3 className="basic-head01-cert certfy-base-head01">
          Request overview
        </h3>
        {status === "success" && bank && !request?.account_verification && (
          <>
            <div className="refetchDropdown requestOverviewTop">
              <div>Days to re-fetch</div>
              <select
                className="form-control org-id-Dropdown bg-white"
                id="refetch_days"
                name="refetch_days"
                value={refetchDays}
                onChange={(e) => handleRefetchDays(e)}
              >
                <option value="">Select</option>
                {
                  refetchDaysArr?.map((days, index) => <option value={days} key={index}>{days}</option>)
                }
              </select>
            </div>
            <button
              className="refetch-transaction-btn"
              type="button"
              onClick={() => setShowTransactionModal(true)}
            >
              Refetch transactions
            </button>
          </>
        )}
      </div>
      {showTransactionModal && (
        <TransactionModal
          bankName={bank === null ? "N/A" : bank}
          setShowTransactionModal={(value) => setShowTransactionModal(value)}
          certificateId={organization_user.id}
          handleSubmit={(id) => fetchTransaction(id)}
        />
      )}
      {showUpdateTransactionModal && (
        <UpdateTransactionModal
          newTransactionCount={newTransactionCount}
          setShowUpdateTransactionModal={(value, e) =>
            updatetransactionModalwithupdateResponse(value, e)
          }
          certificateId={organization_user.id}
          handleSubmit={(id, e) => updateTransaction(id, e)}
          bankName={bank}
        />
      )}
      {status === "success" ? (
        <div className="certify-log-detils certify-log-detils-flex log-label-details-flex">
          <div className="table-head-form">
            <p className="organization_user_id"> {organization_user.id} </p>
            <form
              className="search-input requestOverviewTop"
              type="search"
              onSubmit={(event) => searchList(event)}
              style={{ marginLeft: "0" }}
            >
              <ReactSelect
                orgList={organizationListData}
                value={selectedOrgSelect}
                pageRender={"select cert"}
                handleSelectOrg={handleSelectOrg}
              />

              <select
                className="form-control org-id-Dropdown"
                id="sel1"
                name="search_options"
                value={userPrefer.search_options}
                onChange={(e) => handleSelectSearch(e)}
              >
                <option value="">Select</option>
                <option value="id">Certificate Id</option>
                <option value="case_id">Case Id</option>
                <option value="session_id">Session Id</option>
                {enablePartial ? (
                  <option value="partial_client_reference_id">
                    Partial Client Reference Id
                  </option>
                ) : (
                  <option value="client_reference_id">Client Reference Id</option>
                )}
              </select>
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Search by Id, Case Id, Session_id, Client Reference Id"
                  className="search-control"
                  style={{ width: "300px" }}
                  value={searchKey}
                  onChange={SearchCertID}
                />
                <img
                  className="search-icon"
                  src={search}
                  alt="search"
                  onClick={searchList}
                />
                <button type="submit" onClick={(event) => searchList(event)} className="btn-submit-correct">
                </button>
              </div>
            </form>
          </div>
          <div className="row w-100 mt-4">
            <div className="col-md-3 px-md-0">
              <label className="certificatelogs_label">
                User name | Id :{" "}
              </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {organization_user.name}{" "}
              </p>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data"> | </p>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {organization_user.requested_by}{" "}
              </p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Product : </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {request.type === "customer" ? "Consumer" : "Business"}{" "}
              </p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Bank : </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {bank === null ? "N/A" : bank}{" "}
              </p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Transactions:</label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {transaction ? transaction : "N/A"}{" "}
              </p>
            </div>
            <div className="col-md-2"></div>
            {/* <div className="col-md-2"></div> */}
          </div>
          <div className="row w-100">
            <div className="col-md-3 px-md-0">
              <label className="certificatelogs_label">
                Organization name | Id :{" "}
              </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data"> {organization.name} </p>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data"> | </p>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data"> {organization.id} </p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Type : </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">{keysToName(keysWithTrueValues)}</p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">
                Authentication type:
              </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">N/A</p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Target months of data : </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {(request?.target_months_of_data || request?.target_months_of_data === 0) && request?.target_months_of_data}{" "}
              </p>
            </div>
            <div className="col-md-2">
              <label className="certificatelogs_label">Fetch months of data : </label>
              <span className="cl-whitespace">⠀</span>
              <p className="certificatelogs_data">
                {" "}
                {(request?.fetched_months_of_data || request?.fetched_months_of_data === 0) && request?.fetched_months_of_data}{" "}
              </p>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      ) : (
        <div className="certify-log-detils certify-log-detils-flex-search">
          <div>
            <form
              className="search-input requestOverviewTop"
              type="search"
              onSubmit={(event) => searchList(event)}
              style={{ marginLeft: "0" }}
            >
              <Select
                className="org-id-Dropdown-select"
                styles={customStyles}
                value={selectedOrgSelect}
                options={organizationList}
                onChange={handleSelectOrg}
                placeholder="Select Client"
              />
              <select
                className="form-control org-id-Dropdown"
                id="sel1"
                name="search_options"
                value={userPrefer.search_options}
                onChange={(e) => handleSelectSearch(e)}
              >
                <option value="">Select</option>
                <option value="id">Certificate Id</option>
                <option value="case_id">Case Id</option>
                <option value="session_id">Session Id</option>
                {enablePartial ? (
                  <option value="partial_client_reference_id">
                    Partial Client Reference Id
                  </option>
                ) : (
                  <option value="client_reference_id">Client Reference Id</option>
                )}
              </select>
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Search by Id, Case Id, Session_id, Client Reference Id"
                  className="search-control"
                  style={{ width: "300px" }}
                  value={searchKey}
                  onChange={SearchCertID}
                />
                <img
                  className="search-icon"
                  src={search}
                  alt="search"
                  onClick={searchList}
                />
                <button type="submit" onClick={(event) => searchList(event)} className="btn-submit-correct">
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {certificateUnable &&
        <div>
          {certificateUnable && <h3 className="basic-head01-cert certfy-base-head01">End user request details</h3>}

          {status === "success" ?
            <div className="certify-log-detils certify-log-detils-flex">
              <table className="table">
                <thead>
                  <tr className="table-certlogs">
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">
                      Case Id
                      <span
                        className="hide-show"
                        onClick={handleToggleCaseidVisibility}
                      >
                        {caseidVisibility ? " Hide" : " Show"}
                      </span>
                    </th>
                    <th scope="col">Country</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="email_certlogs">{request.email || "N/A"}</td>
                    <td className="td_certlogs">{request.status}</td>
                    {caseidVisibility ? (
                      <td className="td_toggle_certlogs">{request.case_id}</td>
                    ) : (
                      <td className="email_certlogs"></td>
                    )}
                    <td className="td_certlogs">{request.name}</td>
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">Certificate</th>
                    <th scope="col">Receiver name</th>
                    <th scope="col">Cellphone</th>
                    <th scope="col">Client reference id</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td_certlogs">{request.certificate}</td>
                    <td className="td_certlogs">
                      {request.receiver_name || "N/A"}
                    </td>
                    {request.cellphone === null ? (
                      <td className="email_certlogs">N/A</td>
                    ) : (
                      <td className="email_certlogs">
                        {request.cellphone ? request.cellphone : "N/A"}
                      </td>
                    )}
                    {request.client_reference_id === null ? (
                      <td className="td_certlogs">N/A</td>
                    ) : (
                      <td className="td_certlogs disable-capital">
                        {request.client_reference_id}
                      </td>
                    )}
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">
                      Bank Data Id
                      <span
                        className="hide-show"
                        onClick={handleToggleBankdataVisibility}
                      >
                        {bankdataVisibility ? " Hide" : " Show"}
                      </span>
                    </th>
                    <th scope="col">Callback sent at</th>
                    <th scope="col">Callback succeeded at</th>
                    <th scope="col">Callback response</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {bankdataVisibility ? (
                      <td className="td_toggle_certlogs">
                        {request.bank_data_id ? request.bank_data_id : "N/A"}
                      </td>
                    ) : (
                      <td className="email_certlogs"></td>
                    )}
                    {callbackEvent?.sent_at === null ? (
                      <td className="td_certlogs">N/A</td>
                    ) : (
                      <td>
                        <pre style={{ whiteSpace: "break-spaces", wordBreak: "break-all", color: "#596980", fontFamily: "SF Pro Display" }} >{JSON.stringify(processData(callbackEvent?.sent_at), null, 2)}</pre>
                      </td>
                    )}
                    {callbackEvent?.succeeded_at === null ? (
                      <td className="email_certlogs">N/A</td>
                    ) : (
                      <td>
                        <pre style={{ whiteSpace: "break-spaces", wordBreak: "break-all", color: "#596980", fontFamily: "SF Pro Display" }} >{JSON.stringify(processData(callbackEvent?.succeeded_at), null, 2)}</pre>
                      </td>
                    )}
                    {callbackEvent?.status === null ? (
                      <td className="td_certlogs">N/A</td>
                    ) : (
                      <td>
                        <pre style={{ whiteSpace: "break-spaces", wordBreak: "break-all", color: "#596980", fontFamily: "SF Pro Display" }} >{JSON.stringify(processData(callbackEvent?.status), null, 2)}</pre>
                      </td>
                    )}
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">
                      Service Token
                      <span
                        className="hide-show"
                        onClick={handleToggleTokenVisibility}
                      >
                        {tokenVisibility ? " Hide" : " Show"}
                      </span>
                    </th>
                    <th scope="col">Parent request id</th>
                    <th scope="col">Trs refetch</th>
                    <th scope="col">Env</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="kr-border-none"></td>
                    <td className="kr-border-none email_certlogs capitalize">{request.parent_request_id ? request.parent_request_id : "N/A"}</td>
                    <td className="kr-border-none email_certlogs capitalize">{request.trs_refetch ? "True" : "False"}</td>
                    <td className="kr-border-none email_certlogs capitalize">{request.env ? request.env : "N/A"}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      {tokenVisibility && <pre style={{ whiteSpace: "break-spaces", wordBreak: "break-all", color: "#596980", fontFamily: "SF Pro Display" }} >{JSON.stringify(organization_user?.service_token, null, 2)}</pre>}
                    </td>
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">Type</th>
                    <th scope="col">Bank connected</th>
                    <th scope="col">Local(language)</th>
                    <th scope="col">Annual income year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="kr-border-none email_certlogs capitalize">{request.type ? request.type === "customer" ? "Consumer" : "Business" : "N/A"}</td>
                    <td className="td_certlogs bank-connected">{memberRequestBank?.map((item, index) => <div>{`${index + 1}. ${item.display_name} -${item.id} -${item.status} `}</div>)}</td>
                    <td className="td_uppercase_certlogs">{request.locale}</td>
                    {request.annual_income_year === null ? (
                      <td className="email_certlogs">N/A</td>
                    ) : (
                      <td className="email_certlogs">
                        {request.annual_income_year}
                      </td>
                    )}
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">Company code</th>
                    <th scope="col">Connection type</th>
                    <th scope="col">Account verification</th>
                    <th scope="col">Main account</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {request.company_code === null ? (
                      <td className="td_certlogs">N/A</td>
                    ) : (
                      <td className="td_certlogs">{request.company_code}</td>
                    )}
                    <td className="email_certlogs capitalize">
                      {connection?.name
                        ? connection?.name.replace("_", " ")
                        : "N/A"}
                    </td>
                    <td className="td_certlogs">
                      {request.account_verification ? "True" : "False"}
                    </td>
                    <td className="email_certlogs">
                      {request.main_account ? "True" : "False"}
                    </td>
                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">User entered organization number</th>
                    <th scope="col">
                      Consent Credentials
                      <span
                        className="hide-show"
                        onClick={handleCredentialsVisibility}
                      >
                        {credentialsVisibility ? " Hide" : " Show"}
                      </span>
                    </th>
                    <th scope="col">Requested Documents</th>
                    <th scope="col">Additional Documents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td_certlogs">
                      {request.user_entered_organization_number
                        ? request.user_entered_organization_number
                        : "N/A"}
                    </td>
                    {credentialsVisibility ? (
                      <td className="td_toggle_certlogs">
                        <div>Created via manual/api: <span>{request && request.ssn_number ? request.ssn_number : "N/A"}</span> </div>
                        <div>Created via iframe: <span>{request && request.iframe_ssn ? request.iframe_ssn : "N/A"}</span> </div>
                        <div>Consent view (added by user): <span>{userId && userId.length > 0 ? userId : "N/A"}</span></div>
                        <div>Corporate ID (added by user): <span>{companyId && companyId.length > 0 ? companyId : "N/A"}</span></div>
                        <div>SSN from bank: <span>{request && request.ssn_from_bank ? request.ssn_from_bank : "N/A"}</span> </div>
                      </td>
                    ) : (
                      <td className="td_toggle_certlogs"></td>
                    )}
                    <td className="td_certlogs">
                      {request.requested_documents
                        ? Object.keys(request.requested_documents).length === 0
                          ? "N/A"
                          : request.requested_documents
                        : "N/A"}
                    </td>
                    <td className="td_certlogs">
                      {request.additional_documents
                        ? Object.keys(request.additional_documents).length === 0
                          ? "N/A"
                          : JSON.stringify(request.additional_documents)
                        : "N/A"}
                    </td>

                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">Documents Skipped</th>
                    <th scope="col">Iframe</th>
                    <th scope="col">Api version</th>
                    <th scope="col">Generic request</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td_certlogs">
                      {request.documents_skipped ? "True" : "False"}
                    </td>
                    <td className="email_certlogs capitalize">
                      {request.iframe ? "True" : "False"}
                    </td>
                    <td className="td_certlogs">
                      {request.api_version ? "True" : "False"}
                    </td>
                    <td className="email_certlogs">
                      {request.generic_request ? "True" : "False"}
                    </td>

                  </tr>
                </tbody>
                <thead style={{ borderTopColor: "#f4f6fb" }}>
                  <tr className="table-certlogs">
                    <th scope="col">Is subscriber request</th>
                    <th scope="col">Processing time</th>
                    <th scope="col">Subscriber id</th>
                    <th scope="col">Browser</th>
                    {request.via !== 'manual_request' && request.via !== null ? < th scope="col">Version</th> : <th scope="col"></th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td_certlogs">
                      {request.is_subscriber_request ? "True" : "False"}
                    </td>
                    <td
                      style={{ borderBottom: "none" }}
                      className="email_certlogs td_certlogs"
                    >
                      {<pre style={{ fontSize: "14px", whiteSpace: "break-spaces", wordBreak: "break-all", color: "#596980", fontFamily: "SF Pro Display" }} >{JSON.stringify(request.processing_time, null, 2)}</pre>}
                    </td>
                    <td style={{ borderBottom: "none" }} className="td_certlogs">
                      {request.subscriber_id ? request.subscriber_id : "N/A"}
                    </td>
                    <td
                      style={{ borderBottom: "none" }}
                      className="email_certlogs"
                    ><strong>Browser Name: </strong>{browserDetails?.browser_name ? browserDetails?.browser_name : "N/A"}<br />
                      <strong>Platform Name: </strong>{browserDetails?.browser_device_name ? browserDetails?.browser_device_name : browserDetails?.browser_platform_name ? browserDetails?.browser_platform_name : "N/A"}
                      <br />
                      <strong>Browser Version: </strong>{browserDetails?.browser_full_version ? browserDetails?.browser_full_version : "N/A"}
                    </td>
                    {request.via !== 'manual_request' && request.via !== null && <td
                      style={{ borderBottom: "none" }}
                      className="td_certlogs"
                    >{request.api_version ? request.api_version : 1}</td>}
                  </tr>
                </tbody>
              </table >
            </div > :
            <div className="certify-log-detils certify-log-detils-flex">
            </div>}
        </div >}
      <div>
        {certificateUnable && <h3 className="basic-head01-cert certfy-base-head01">Activity Logs</h3>}
      </div>
      {
        certificateUnable &&
        <div>
          {status === "success" ?
            <div className="certify-log-detils certify-log-detils-flex">
              <table className="table">
                <thead>
                  <tr className="table-certlogs">
                    <th style={{ width: "250px" }}>Date/Time</th>
                    <th style={{ width: "250px" }}>Event Type</th>
                    <th>Column Affected</th>
                  </tr>
                </thead>
                <tbody>
                  {event_logs.map((item, index) => (
                    item.length > 10 ? (
                      <>
                        <tr
                          style={{ borderBottom: "1px solid #f4f6fb" }}
                          key={Math.random()}
                          onClick={() => toggleRow(index)}
                        >
                          {!openRows.has(index) ? <>
                            <td className="td_certlogs">{moment(item[0].created_at).format("D MMM, YYYY h:mm:ss A")}</td>
                            <td className="td_certlogs">{customeEventType(item[0])}</td>
                          </> : <>
                            <td className="td_certlogs">
                            </td>
                            <td className="td_certlogs">
                            </td>
                          </>}
                          <td className="text-center">
                            <span className='sub-account-container'>
                              {openRows.has(index) ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </span>
                          </td>
                        </tr>
                        {openRows.has(index) && item.map(x => (
                          <tr
                            style={{ borderBottom: "1px solid #f4f6fb" }}
                            key={Math.random()}
                          >
                            <td className="td_certlogs">{moment(x.created_at).format("D MMM, YYYY h:mm:ss A")}</td>
                            <td className="td_certlogs">{customeEventType(x)}</td>
                            <td className="td_token_certlogs">{showEventType(x)}</td>
                          </tr>
                        ))}
                      </>

                    ) : item.map(data => (<tr
                      style={{ borderBottom: "1px solid #f4f6fb" }}
                      key={Math.random()}
                    >
                      <td className="td_certlogs">{moment(data.created_at).format("D MMM, YYYY h:mm:ss A")}</td>
                      <td className="td_certlogs">{customeEventType(data)}</td>
                      <td className="td_token_certlogs">{showEventType(data)}</td>
                    </tr>))
                  ))}
                </tbody>
              </table>
            </div> :
            <div className="certify-log-detils certify-log-detils-flex">
            </div>}
        </div>
      }
      <div>
        {certificateUnable && <h3 className="basic-head01-cert certfy-base-head01">Error Logs</h3>}
      </div>
      {
        certificateUnable &&
        <div>
          {status === "success" ?
            <div className="certify-log-detils certify-log-detils-flex">
              <table className="table">
                <thead>
                  <tr className="table-certlogs error-logs">
                    <th scope="col">Id</th>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Request Id</th>
                    <th scope="col">Error Type</th>
                    <th scope="col">Message</th>
                    <th scope="col">Error Message</th>
                    <th scope="col">Data</th>
                    <th scope="col">Service Name</th>
                  </tr>
                </thead>
                <tbody>{renderErrorLogs}</tbody>
              </table>
            </div>
            :
            <div className="certify-log-detils certify-log-detils-flex">
            </div>}
        </div>
      }
    </div >

  )
}

export default CertificateLogsList;