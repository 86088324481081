import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { logout, setHeaderFromLocalStorage } from "../../Redux/Actions/Auth";

const INACTIVITY_TIMEOUT = process.env.REACT_APP_LOGIN_TIMER
const ACTIVITY_KEY = "user-activity";
const LOGOUT_KEY = "user-logout";
const AUTH_TOKEN_KEY = "authToken";


const AutoLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const timeoutRef = useRef(null);
  const excludedRoutes = ["/live_data_overview", "/live_data_markets", "/live_data_organizations", "/live_data_worldmap", "/live_data_customers", "/live_data_banks", "/markets"];

  useEffect(() => {
    const resetInactivityTimer = () => {
      if (!excludedRoutes.includes(location.pathname)) {
        clearTimeout(timeoutRef.current);
        startInactivityTimer();
        localStorage.setItem(ACTIVITY_KEY, Date.now().toString());
      }
    };

    const handleStorageEvent = (event) => {
      if (event.key === ACTIVITY_KEY && !excludedRoutes.includes(location.pathname)) {
        clearTimeout(timeoutRef.current);
        startInactivityTimer();
      }

      if (event.key === LOGOUT_KEY) {
        performLogout();
      }

      if (event.key === AUTH_TOKEN_KEY) {
        const newToken = event.newValue;
        if (newToken) {
          syncAuthToken(newToken);
        }
      }
    };

    if (!excludedRoutes.includes(location.pathname)) {
      startInactivityTimer();
    }

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("storage", handleStorageEvent);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [location.pathname]);

  const startInactivityTimer = () => {
    timeoutRef.current = setTimeout(() => {
      logoutUser();
    }, INACTIVITY_TIMEOUT);
  };

  const performLogout = async () => {
    await dispatch(logout());
    history.push("/login");
  };

  const logoutUser = async () => {
    await dispatch(logout());
    localStorage.setItem(LOGOUT_KEY, Date.now().toString());
    localStorage.clear();
    history.push("/login");
  };

  const syncAuthToken = (token) => {
    dispatch(setHeaderFromLocalStorage())
  };

  return null;
};

export default AutoLogout;