import ReactSelect from "react-select";
import CreateEndpointModal from "../modals/CreateEndpointModal";
import { useState } from "react";
import { createOrganizationEndpoint } from "../../../Redux/Actions/OrganizationsEndpoints";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";

const SelectMenu = ({
  setSelectedOrgId,
  setSelectedEndpointId,
  endpointsList = [],
  organizationsList = [],
  fetchOrganizationsEndpoints,
  fetchOrganizationList
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [organization, setOrganization] = useState([]);
  const dispatch = useDispatch();
  const [endpointName, setEndpointName] = useState("");
  const [endpointValue, setEndpointValue] = useState("");

  const handleOrgChange = (selectedOption) => {
    setSelectedOrgId(selectedOption ? selectedOption.value : null);
    setSelectedOrg(selectedOption);
  };

  const handleEndpointChange = (selectedOption) => {
    setSelectedEndpointId(selectedOption ? selectedOption.value : null);
    setSelectedEndpoint(selectedOption);
  };

  const handleCreateClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    const endpointPayload = {
      analytics_endpoint: {
        name: endpointName,
        endpoint: endpointValue, 
      },
      organization_ids: organization,
    };
    dispatch(setLoaderSpinner(true));
    try {
      const response = await dispatch(createOrganizationEndpoint(endpointPayload));
      if (response.status) {
        handleCloseCreateModal();
        fetchOrganizationList();
        fetchOrganizationsEndpoints();
        setSelectedOrg([]); 
        setSelectedEndpoint(null); 
        setOrganization([]);
      } else {
        console.error("Error:", response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  };

  return (
    <div className="endpoint-selects-container">
      <div className="email-sms-select">
        <ReactSelect
          value={selectedEndpoint}
          onChange={handleEndpointChange}
          options={endpointsList.map((endpoint) => ({
            value: endpoint.id,
            label: endpoint.name,
          }))}
          placeholder="Select endpoint"
          isClearable={true}
          noOptionsMessage={() => "No endpoints found"}
          className="email-sms-select-org-dropdown"
        />
        <ReactSelect
          value={selectedOrg}
          onChange={handleOrgChange}
          options={organizationsList.map((organization) => ({
            value: organization.id,
            label: `${organization.name}: ${organization.id}`
            
          }))}
          placeholder="Select organization"
          isClearable={true}
          noOptionsMessage={() => "No organizations found"}
          className="email-sms-select-org-dropdown"
        />
        <button className="endpoint-create-button" onClick={handleCreateClick}>
          Create Endpoint
        </button>
      </div>
      {isCreateModalOpen && (
        <CreateEndpointModal
          organization={organization}
          setOrganization={setOrganization}
          setEndpointName={setEndpointName}
          handleSubmit={handleCreateSubmit}
          handleCloseModal={handleCloseCreateModal}
          organizationsList={organizationsList}
          endpointValue={endpointValue}
          endpointName={endpointName}
          setEndpointValue={setEndpointValue}
        />
      )}
    </div>
  );
};

export default SelectMenu;