import axios from "axios";
import { API_CONFIG } from "../../config/api";
import { removeHeaderWithLocalstorage } from "../Actions/Auth";

export function getOrganizationsEndpoints() {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATIONS_ENDPOINTS.LIST()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data };
    }).catch(err => {
      if (err.response.status === 401) removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    });
}

export function createOrganizationEndpoint(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATIONS_ENDPOINTS.CREATE()}`;
  const payload = {
    analytics_endpoint: {
      name: data.analytics_endpoint.name,        
      endpoint: data.analytics_endpoint.endpoint,  
      organization_ids: data.organization_ids
    },
  };
  return dispatch => axios.post(apiURL, payload)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    });
}

export function updateOrganizationEndpoint(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATIONS_ENDPOINTS.UPDATE(data.analytics_endpoint.id)}`;
  const payload = {
    analytics_endpoint: {
      id: data.analytics_endpoint.id,
      status: data.analytics_endpoint.status,
      organization_ids: data.organization_ids,
    },
  };
  return (dispatch) =>
    axios
      .put(apiURL, payload)
      .then((res) => {
        return { status: true, message: res.data.status, data: res.data };
      })
      .catch((err) => {
        err.response.status === 401 && removeHeaderWithLocalstorage();
        return {
          status: false,
          message: err.response.data.error || err.response.data.message,
        };
      });
}

export function pingEndpoint(data, selectedOrgId) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATIONS_ENDPOINTS.PING(data.analytics_endpoint.id)}`;
  return (dispatch) => axios.post(apiURL, { organization_id: selectedOrgId })
    .then((res) => {
      return { status: true, message: res.data.status, data: res.data };
    }).catch((err) => {
      if (err.response.status === 401) {
        removeHeaderWithLocalstorage();
      }
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function getRequestsList(analytics_endpoint_id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATIONS_ENDPOINTS.REQUESTS(analytics_endpoint_id)}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data.requests };
    }).catch(err => {
      if (err.response.status === 401) removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    });
}