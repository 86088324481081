import { useState, useEffect } from "react"
import ToggleField from "../ToggleField/ToggleField"
const ProductTab = ({
  setClientDetails,
  clientDetails,
  capitalizeFirstLetter,
  submitHandle, }) => {

  const [certificateConsumer, setCertificateConsumer] = useState(false)

  const handleCertificateConsumer = (event, type) => {
    if (type === 'all') {
      setCertificateConsumer(event.target.checked)
      let setting = clientDetails.setting
      setting.certificate_tabs = {
        overview: event.target.checked,
        account_information: event.target.checked,
        income_tab: event.target.checked,
        expense_tab: event.target.checked,
        assets: event.target.checked,
        debts: event.target.checked,
        transactions: event.target.checked,
        business_rules: event.target.checked,
        sources: event.target.checked,
        credit_decision_application: event.target.checked,
        credit_decision_surveillance: event.target.checked,
        skanska: event.target.checked
      }
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    } else {
      setCertificateConsumer(false)
      let setting = clientDetails.setting;
      setting.certificate_tabs[type] = event.target.checked
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
  }


  const handleCertificateCorporate = (event, type) => {
    if (type === 'business_rules') {
      let setting = clientDetails.setting
      if (event.target.checked === true) {
        setting.corporate_tabs.business_rules = event.target.checked
        setting.corporate_tabs.credit_score = false
        setClientDetails(prevState => ({
          ...prevState,
          setting: setting
        }))
      } else {
        setting.corporate_tabs.business_rules = event.target.checked
        setClientDetails(prevState => ({
          ...prevState,
          setting: setting
        }))
      }
    } else if (type === 'credit_score') {
      let setting = clientDetails.setting
      if (event.target.checked === true) {
        setting.corporate_tabs.business_rules = false
        setting.corporate_tabs.credit_score = event.target.checked
        setClientDetails(prevState => ({
          ...prevState,
          setting: setting
        }))
      } else {

        setting.corporate_tabs.credit_score = event.target.checked
        setClientDetails(prevState => ({
          ...prevState,
          setting: setting
        }))
      }
    } else {
      let setting = clientDetails.setting
      setting.corporate_tabs[event.target.name] = event.target.checked

      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
  }

  useEffect(() => {
    if (clientDetails?.setting?.certificate_tabs?.overview && clientDetails?.setting?.certificate_tabs?.account_information && clientDetails?.setting?.certificate_tabs?.income_tab && clientDetails?.setting?.certificate_tabs?.expense_tab && clientDetails?.setting?.certificate_tabs?.assets && clientDetails?.setting?.certificate_tabs?.debts && clientDetails?.setting?.certificate_tabs?.transactions && clientDetails?.setting?.certificate_tabs?.business_rules && clientDetails?.setting?.certificate_tabs?.credit_decision_application && clientDetails?.setting?.certificate_tabs?.credit_decision_surveillance && clientDetails?.setting?.certificate_tabs?.skanska) {
      setCertificateConsumer(true)
    } else {
      setCertificateConsumer(false)
    }
  }, [clientDetails])

  return (
    <div id="products_details">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <h3 className="headeing-switch">
            Certificate Consumer
            <div className="adjust-switcher">
              ALL
              <div className="select-switch">
                <label className="switch-box">
                  <input
                    type="checkbox"
                    checked={certificateConsumer}
                    onChange={(e) => handleCertificateConsumer(e, 'all')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {['overview', 'account_information', 'income_tab', 'expense_tab', 'assets', 'debts', 'business_rules', 'credit_decision_application', 'credit_decision_surveillance', 'transactions', 'skanska'].map((keys, index) => (
            <ToggleField
              keys={keys}
              checked={clientDetails.setting.certificate_tabs[keys]}
              toggleFunction={handleCertificateConsumer}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
            />
          ))}
        </div>
        <div className="details-right details-full-box">
          <h3 className="headeing-switch">
            Certificate Corporate
          </h3>
          {['overview', 'account_information', 'sources', 'cashflow', 'business_rules', 'credit_score', 'transactions', 'documents', 'skanska', 'balance_sheet', 'discrepancies', 'growth', 'income_statement', 'invoices', 'profitability', 'extra_response_data'].map((keys, index) => (
            <ToggleField
              keys={keys}
              checked={clientDetails.setting.corporate_tabs[keys]}
              toggleFunction={handleCertificateCorporate}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className="create-client-button">
        <button onClick={submitHandle}>Update</button>
      </div>
    </div>
  )
}

export default ProductTab