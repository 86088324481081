import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_CURRENT_USER, DELETE_CURRENT_USER } from '../../Redux/Actions/Types/Types';
import setRequestHeader, { deleteRequestHeader } from '../../Utils/setRequestHeader'

const LOGOUT_KEY = "user-logout";


export function login(loginData) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.SIGN_IN()}`;
  return dispatch => axios.post(apiURL, loginData)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        localStorage.setItem('id', res.data.data.id);
        localStorage.setItem('name', res.data.data.name);
      }
      return { status: true, message: res.data.message, authToken: res.data.data.auth_token };
    }).catch(err => {
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function get_otp_via(loginData) {
  let apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.GET_OTP_VIA()}`;
  apiURL = apiURL + '?type=' + loginData.type;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function forgetPassword(Data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.FORGET_PASSWORD()}`;
  return dispatch => axios.post(apiURL, Data)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
      }
      return { status: true, message: res.data.message };
    }).catch(err => {
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}
export function verify_tfa(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.VERIFY_TFA()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        setRequestHeader(res.data.data);
        dispatch(setCurrentUser(res.data.data));
        addUserDataToLocalStorage(res.data.data);
      }
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function resetPassword(Data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.RESET_PASSWORD()}`;
  return dispatch => axios.put(apiURL, Data)
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function newPasswordTokenValidation(tokenData) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.NEW_PASSWORD_TOKEN_VALIDATION()}?activation_token=${tokenData.token}`;
  return dispatch => axios.get(apiURL, { params: tokenData })
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      return { status: false, message: err.response?.data?.error || err.response?.data?.message };
    });
}

export function newPasswordEmailValidation(data) {
  const { token, email } = data;
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.NEW_PASSWORD_EMAIL_VALIDATION()}?activation_token=${token}&email=${email}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      return { status: false, message: err.response?.data?.error || err.response?.data?.message };
    });
}

export function newPasswordSet(Data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.NEW_PASSWORD_SET()}`;
  return dispatch => axios.put(apiURL, Data)
    .then(res => {
      return { status: true, message: res.data.message };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function logout() {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.SIGN_OUT()}`;
  return dispatch => axios.delete(apiURL)
    .then(res => {
      const success = res.data.status !== 'error'
      if (success) {
        localStorage.setItem(LOGOUT_KEY, Date.now().toString());
        removeHeaderWithLocalstorage();
      }
      return { status: true, message: res.data.message };

    }).catch(err => {
      if (err.response.data.status === 'error') {
        removeHeaderWithLocalstorage();
      }
      return { status: false, message: err.response.data.error || err.response.data.message };
    });
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user: user?.user
  }
}

export function deleteCurrentUser() {
  return {
    type: DELETE_CURRENT_USER
  }
}

export function addUserDataToLocalStorage(data) {
  var today = new Date();
  localStorage.setItem('id', data?.user?.id);
  localStorage.setItem('authToken', data?.user?.auth_token);
  localStorage.setItem('email', data?.user?.email);
  localStorage.setItem('role', data?.user?.role);
  localStorage.setItem('access-settings', JSON.stringify(data?.user?.access_settings) || JSON.stringify({}))
  localStorage.setItem('platform', 'WEB-APP');
  localStorage.setItem('app-version', "app-version");
  localStorage.setItem('Content-Type', "application/json")
  if (data.phone_number) {
    localStorage.setItem('phone_number', data.phone_number);
  }
  if (data.phone_country_code) {
    localStorage.setItem('phone_country_code', data.phone_country_code)
  }
}

export function removeUserDataToLocalstorage() {
  return dispatch => {
    localStorage.removeItem('id')
    localStorage.removeItem('authToken');
    localStorage.removeItem('email')
    localStorage.removeItem('role')
    localStorage.removeItem('access-settings');
    localStorage.removeItem('platform');
    localStorage.removeItem('app-version');
    localStorage.removeItem('Content-Type');
  }
}

export function setHeaderFromLocalStorage() {
  return dispatch => {
    axios.defaults.headers.common['access-token'] = localStorage.getItem('authToken');
    axios.defaults.headers.common['email'] = localStorage.getItem('email');
    axios.defaults.headers.common['role'] = localStorage.getItem('role');
    axios.defaults.headers.common['platform'] = "WEB-APP";
    axios.defaults.headers.common['app-version'] = "1.0";
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }
}

export function removeHeaderWithLocalstorage() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('email')
  localStorage.removeItem('role')
  localStorage.removeItem('platform');
  localStorage.removeItem('app-version');
  localStorage.removeItem('Content-Type');
  deleteRequestHeader();
}