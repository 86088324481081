import ReactPaginate from 'react-paginate';
import { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const CertificateInformationTable = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dateRangeChanged = useRef(false);

  const initialPage = location.state?.currentPage || 0;
  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    props.setItemOffset(currentPage * itemsPerPage);
  }, [currentPage]);

  useEffect(() => {
    if (dateRangeChanged.current) {
      setCurrentPage(0);
      props.setItemOffset(0);
    } else {
      dateRangeChanged.current = true;
    }
  }, [props.selectedDateRange]);

  const sortedData = props.orgData.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const pageCount = Math.ceil(sortedData?.length / itemsPerPage);
  const endOffset = currentPage * itemsPerPage + itemsPerPage;
  const currentItems = sortedData?.slice(currentPage * itemsPerPage, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % sortedData?.length;
    props.setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  const certificateDetails = async (orgId, certId) => {
    history.push({
      pathname: "/certificate_information_view",
      state: {
        orgId: orgId,
        certId: certId,
        selectedMarket: props.selectedMarket,
        selectedOrg: props.selectedOrg,
        selectedDateRange: props.selectedDateRange,
        selectedOrgSelect: props.selectedOrgSelect,
        currentPage: currentPage,
        showAcceptedOnly: props.showAcceptedOnly,
      },
    });
  };

  return (
    <section className="accepted-requests-section">
      <table width="100%">
        <thead className="cert-info-thead">
          <tr>
            <th className="table-head-cert-info">
              Date
            </th>
            <th className="table-head-cert-info">
              Status
            </th>
            <th className="table-head-cert-info">
              Source
            </th>
            <th className="table-head-cert-info">
              Certificate ID
            </th>
            <th className="table-head-cert-info">
              Certificate type
            </th>
            <th className="table-head-cert-info">
              Market
            </th>
            <th className="table-head-cert-info">
              Client reference ID
            </th>
            <th className="table-head-cert-info">
              Environment
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((data, index) => (
            <tr
              key={index}
              onClick={() => certificateDetails(data.organization_id, data.certificate_id)}
            >
              <td className="cert-info-td">
                {data.date === null || data.date === "" ? "N/A" : data.date.slice(0, 10).replace(/-/g, "/")}
              </td>
              <td className="cert-info-td">
                {data.status === null || data.status === "" ? "N/A" : (data.status.replace(/_/g, " ").charAt(0).toUpperCase() + (data.status.replace(/_/g, " ")).slice(1))}
              </td>
              <td className="cert-info-td">
                {data.source === null || data.source === "" ? "N/A" :
                  data.source === "iframe"
                    ? "iFrame" : data.source === "api"
                      ? "API" : data.source === "manual_request"
                        ? "Manual request" : data.source === "external_payload"
                          ? "External payload" : data.source
                }
              </td>
              <td className="cert-info-td">
                {data.certificate_id === null || data.certificate_id === "" ? "N/A" :
                  data.certificate_id}
              </td>
              <td className="cert-info-td">
                {data.certificate_type === null || data.certificate_type === "" ? "N/A" :
                  data.certificate_type.charAt(0).toUpperCase() + (data.certificate_type.replace(/_/g, " ")).slice(1)}
              </td>
              <td className="cert-info-td">
                {data.market === null || data.market === "" ? "N/A" :
                  data.market}
              </td>
              <td className="cert-info-td">
                {data.client_reference_id === null || data.client_reference_id === "" ? "N/A" : data.client_reference_id}
              </td>
              <td className="cert-info-td">
                {data.env.charAt(0).toUpperCase() + (data.env.replace(/_/g, " ")).slice(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {sortedData.length > 20 &&
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center pagination-access-codes"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          forcePage={currentPage}
        />}
    </section>
  )
}

export default CertificateInformationTable;