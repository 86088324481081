  import {
  SET_BANK_LIST,
  SET_BANK
} from '../Actions/Types/Types';

const initialState = {
  bank_list: [],
  bank: {}
}

const OrganizationManagement = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_BANK_LIST:
      return {
        ...state,
        bank_list: action.bank_list
      }
    case SET_BANK:
      return {
        ...state,
        bank: action.bank
      }
    default: return state;
  }
}

export default OrganizationManagement;