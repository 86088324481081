import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const TestEndpointInfoModal = ({ handleCloseModal, testEndpointInfo }) => {
  const [isResponseExpanded, setIsResponseExpanded] = useState(false);

  const toggleResponseExpansion = () => {
    setIsResponseExpanded(!isResponseExpanded);
  };

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("endpoint-modal")) {
      handleCloseModal();
    }
  };

    const boolToClass = (status) => {
      return status ? "success-result" : "failed-result";
  };

  return (
    <div className="endpoint-modal" onClick={handleBackdropClick}>
      <div className="endpoint-modal-content">
        <h3>Endpoint Information</h3>
        <div className="endpoint-info-group">
          {testEndpointInfo.response_error ? (
            <p className="endpoint-error">{testEndpointInfo.response_error}</p>
          ) : (
            <>
              <p className="endpoint-label">Name:{testEndpointInfo.analytics_endpoint.name.charAt(0).toUpperCase() + testEndpointInfo.analytics_endpoint.name.slice(1)}</p>
              <p className="endpoint-label">Endpoint: {testEndpointInfo.analytics_endpoint.endpoint}</p>
              <p className="endpoint-label">Duration: {(testEndpointInfo.execution_time / 1000).toFixed(3)}s</p>
              <p className={`endpoint-label ${boolToClass(testEndpointInfo.is_valid)}`}>Valid: {testEndpointInfo.is_valid ? "True" : "No"}</p>    
              <p className="endpoint-label">Created At: {new Date(testEndpointInfo.created_at).toLocaleString()}</p>
              <div className="endpoint-label" onClick={toggleResponseExpansion}>
                Response:
                <FontAwesomeIcon className="endpoint-arrow-icon" icon={isResponseExpanded ? faSortUp : faSortDown} />
              </div>
              {isResponseExpanded && (
                <div className="endpoint-expanded-response">
                  <pre className="endpoint-expanded-reponse-pre">{JSON.stringify(testEndpointInfo.response, null, 2)}</pre>
                </div>
              )}
            </>
          )}
        </div>
        <div className="endpoint-modal-buttons-container">
          <button className="endpoint-button" onClick={handleCloseModal}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default TestEndpointInfoModal;