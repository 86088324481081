import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NoRouteFound from "./Pages/PageNotFound/PageNotFound";
import LoginPage from "./Pages/Login/Login";
import DashboardPage from "./Pages/Dashboard/dashboard";
import EmailPage from "./Pages/Email/email";
import SmsPage from "./Pages/Sms/sms";
import LiveDataPage from "./Pages/LiveData/LiveDataOverviewPage";
import LiveDataOrganisationsPage from "./Pages/LiveData/LiveDataOrganisationsPage";
import LiveDataMarketsPage from "./Pages/LiveData/LiveDataMarketsPage";
import LiveDataWorldmapPage from "./Pages/LiveData/LiveDataWorldmapPage";
import LiveDataCustomersPage from "./Pages/LiveData/LiveDataCustomersPage";
import LiveDataBanksPage from "./Pages/LiveData/LiveDataBanksPage";
import MarketsPage from "./Pages/LiveData/MarketsPage";
import StatisticsPage from "./Pages/Statistics/statistics";
import OrganizationPage from "./Pages/Organization/organization";
import TestCenterPage from "./Pages/TestCenter/testCenter";
import CreateSubscriber from "./Pages/TestCenter/CreateSubscriber";
import ActiveSubscribersPage from "./Pages/TestCenter/activeSubscribersPage";
import EditSubscriberPage from "./Pages/TestCenter/editSubscriberPage";
import CertificateLogsPage from "./Pages/CertificateLogs/certificateLogs";
import InvoicingPage from "./Pages/Invoicing/invoicing";
import AdminLogsPage from "./Pages/AdminLogs/adminLogs";
import UserManagementPage from "./Pages/UserManagement/userManagement";
import ProfilePage from "./Pages/Profile/profile";
import ChangePasswordPage from "./Pages/ChangePassword/changePassword";
import CreateUser from "./Pages/CreateUser/CreateUser";
import EditUser from "./Pages/EditUser/EditUser";
import LoaderSpinner from "./Components/LoaderSpinner/loaderspinner";
import CreateOrganization from "./Pages/CreateOrganization/CreateOrganization";
import AuditReport from "./Pages/AuditReport/auditReport";
import DemoAggregation from "./Pages/DemoAggregation/demoAggregation";
import AccessCodes from "./Pages/DemoAggregation/accessCodes";
import EditOrganization from "./Pages/EditOrganization/EditOrganization";
import ReportsPage from "./Pages/ReportsPage/reports";
import ConversionRate from "./Pages/ConversionRate/ConversionRate";
import ServiceSettings from "./Pages/ServiceSettings/ServiceSettings";
import HealthcheckPage from "./Pages/Healthcheck/HealthcheckPage";
import HealthcheckEditPage from "./Pages/Healthcheck/HealthcheckEditPage";
import KreditzProviders from "./Pages/KreditzProviders/KreditzProviders";
import OrganizationProviders from "./Pages/OrganizationProviders/OrganizationProviders";
import ForgetPassword from "./Components/ForgetPassword/forgetPassword";
import ResetPassword from "./Components/ResetPassword/resetPassword";
import NewPassword from "./Components/NewPassword/newPassword";
import NewPasswordEmailValidation from "./Components/NewPassword/newPasswordEmailValidation";
import Authentication from "./Components/Authentication/authentication";
import BankConnectionFrequency from "./Pages/BankConnectionFrequency/BankConnectionFrequency";
import ComparisonReport from "./Pages/ComparisonReport/ComparisonReport";
import ItMenu from "./Pages/ItMenu/itMenu";
import OrganizationNoActivity from "./Pages/OrgNoActivity/OrgNoActivity";
import ConversionRateBanksPage from "./Pages/ConversionRate/ConversionRateBanksPage";
import VistaAccoutnUsers from "./Pages/VistaAccountUsers/VistaAccountUsers";
import AcceptedRequestsPage from "./Pages/Statistics/AcceptedRequestsPage";
import CustomerActivityMtdPage from "./Pages/Statistics/CustomerActivityMtdPage";
import Banners from "./Pages/Banners/Banners";
import CreateBanner from "./Pages/Banners/CreateBanners";
import EditBanners from "./Pages/Banners/EditBanners";
import ActiveCustomersPage from "./Pages/Statistics/ActiveCustomersPage";
import ProvidersModesPage from "./Pages/Statistics/ProvidersModesPage";
import CertificateInformationPage from "./Pages/CertificateInformation/CertificateInformationPage";
import CertificateInfoViewPage from "./Pages/CertificateInformation/CertificateInfoViewPage";
import BankStatus from "./Pages/BankStatus/BankStatus";
import TotalClients from "./Pages/TotalClients/TotalClients";
import NewClientsWeek from "./Pages/NewClientWeek/NewClientsWeek";
import NewClientMonth from "./Pages/NewClientMonth/NewClientMonth";
import NewClientsYear from "./Pages/NewClientsYear/NewClientsYear";
import BackgroundJobsPage from "./Pages/BackgroundJobs/BackgroundJobsPage";
import BackgroundJobsDetailPage from "./Pages/BackgroundJobs/BackgroundJobsDetailPage";
import EditUserItPage from "./Pages/EditUserIT/EditUserIt";
import OrganizationsBrokerFlowPage from "./Pages/OrganizationsBrokerFlow/OrganizationsBrokerFlowPage";
import OrganizationsEventLogPage from "./Pages/OrganizationEventLog/OrganizationsEventLogPage";
import UploadFilesPage from "./Pages/UploadFilesPage/uploadFilesPage";
import OrganizationsEmailSmsPage from "./Pages/OrganizationsEmailSms/OrganizationsEmailSmsPage";
import ItMenuPage from "./Pages/ItMenu/itMenu";
import ApiDocumentationEditPage from "./Pages/ApiDocumentationEdit/ApiDocumentationEditPage";
import ApiDocumentationNewPage from "./Pages/ApiDocumentationNew/ApiDocumentationNewPage";
import ApiDocumentationClonePage from "./Pages/ApiDocumentationClone/ApiDocumentationClonePage";
import ApiDocumentationPage from "./Pages/ApiDocumentation/ApiDocumentationPage";
import ServiceProvidersPage from "./Pages/ServiceProvider/ServiceProvider";
import OrganizationNotificationEmailsPage from "./Pages/OrganizationNotificationEmails/OrganizationNotificationEmailsPage";
import OrganizationNotificationEmails from "./Components/OrganizationNotificationEmails/OrganizationNotificationEmails";
import OrganizationConsentTextsPage from "./Pages/OrganizationConsentTexts/OrganizationConsentTexts";
import CreateOrgConsentTextsPage from "./Pages/CreateOrganizationConsentTexts/CreateOrganizationConsentTexts";
import OrganizationConsentTextsDiffPage from "./Pages/OrganizationConsentTextsDiff/OrganizationConsentTextsDiff";
import OrganizationsEndpointsPage from "./Pages/OrganizationsEndpoints/OrganizationsEndpointsPage";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <div className="app-frame">{this.props.children}</div>;
  }
}

const isComponentRenderCall = (authed, path) => {
  const role = localStorage.role;
  let isComponentRender = false;
  if (authed) {
    if (role === "sales") {
      isComponentRender = [
        "/dashboard",
        "/live_data_overview",
        "/live_data_markets",
        "/live_data_organizations",
        "/live_data_worldmap",
        "/live_data_customers",
        "/live_data_banks",
        "/markets",
        "/statistics",
        "/customer_activity",
        "/accepted_requests",
        "/active_customers",
        "/providers_modes",
        "/vista_account_users",
        "/profile",
        "/update_password",
        "/logout",
        "/conversion_rate_overview",
        "/conversion_rate_banks",
        "/verify_tfa",
        "/bank_connection_frequency",
        "/comparison_report",
        "/org_no_activity",
        "/org_broker_flow",
        "/org_eventlog",
        "/org_email_sms",
        "/org_notification_emails",
        "/org_endpoints",
      ].includes(path);
    } else if (role === "operations") {
      isComponentRender = [
        "/dashboard",
        "/live_data_overview",
        "/live_data_markets",
        "/live_data_organizations",
        "/live_data_worldmap",
        "/live_data_customers",
        "/live_data_banks",
        "/markets",
        "/statistics",
        "/customer_activity",
        "/accepted_requests",
        "/active_customers",
        "/providers_modes",
        "/vista_account_users",
        "/demo_access_codes",
        "/api_access_codes",
        "/sms",
        "/reports",
        "/emails",
        "/invoicing",
        "/invoicing/details",
        "/certificateLogs",
        "/profile",
        "/update_password",
        "/logout",
        "/conversion_rate_overview",
        "/conversion_rate_banks",
        "/verify_tfa",
        "/bank_connection_frequency",
        "/comparison_report",
        "/org_no_activity",
        "/org_eventlog",
        "/certificate_information",
        "/certificate_information_view",
        "/org_broker_flow",
        "/org_email_sms",
        "/org_notification_emails",
        "/org_endpoints",
      ].includes(path);
    }
    if (role === "it") {
      isComponentRender = [
        "/profile",
        "/update_password",
        "/logout",
        "/service_settings",
        "/kreditz_providers",
        "/organization_providers",
        "/verify_tfa",
        "/org_no_activity",
        "org_eventlog",
        "/certificate_information",
        "/certificate_information_view",
        "/background_jobs",
        "/org_broker_flow",
        "/org_email_sms",
        "/org_endpoints",
        "/api_documentation",
        "/api_new",
        "/api_clone",
        "/api_update",
        "/org_notification_emails",
      ]
    } else if (role === "admin") {
      isComponentRender = [
        "/service_settings",
        "/kreditz_providers",
        "/organization_providers",
        "/verify_tfa",
        "/org_no_activity",
        "org_eventlog",
        "/certificate_information",
        "/certificate_information_view",
        "/background_jobs",
        "/org_broker_flow",
        "/org_email_sms",
        "/org_endpoints",
        "/api_documentation",
        "/api_new",
        "/api_clone",
        "/api_update",
        "/org_notification_emails",
      ]
    }
  } else {
    isComponentRender = false;
  }
  return isComponentRender;
};

function PrivateRoute({ component: Component, authed, path, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isComponentRenderCall(authed, path) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicOnlyRoute({ component: Component, authed, path, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
}


const App = ({ location, history, ...props }) => {
  const authenticated = localStorage.authToken ? true : false;
  return (
    <ScrollToTop>
      <LoaderSpinner />
      <Switch>
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/dashboard"
          component={DashboardPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/total_clients"
          component={TotalClients}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new_clients_week"
          component={NewClientsWeek}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new_clients_month"
          component={NewClientMonth}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/texts"
          component={OrganizationConsentTextsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/manage_organization_consent_texts"
          component={CreateOrgConsentTextsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/organization_consent_texts_difference"
          component={OrganizationConsentTextsDiffPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/service_providers"
          component={ServiceProvidersPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new_clients_year"
          component={NewClientsYear}
        />

        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/certificate_deletion"
          component={ItMenuPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/api_documentation"
          component={ApiDocumentationPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/api_update"
          component={ApiDocumentationEditPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/api_new"
          component={ApiDocumentationNewPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/api_clone"
          component={ApiDocumentationClonePage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_overview"
          component={LiveDataPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/upload_files"
          component={UploadFilesPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_markets"
          component={LiveDataMarketsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_organizations"
          component={LiveDataOrganisationsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_worldmap"
          component={LiveDataWorldmapPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_customers"
          component={LiveDataCustomersPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/live_data_banks"
          component={LiveDataBanksPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/markets"
          component={MarketsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/organization_user"
          component={EditUserItPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/requests"
          component={StatisticsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/vista_account_users"
          component={VistaAccoutnUsers}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/accepted_requests"
          component={AcceptedRequestsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/customer_activity"
          component={CustomerActivityMtdPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/active_customers"
          component={ActiveCustomersPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/providers_modes"
          component={ProvidersModesPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/demo_access_codes"
          component={DemoAggregation}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/api_access_codes"
          component={AccessCodes}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/organizations"
          component={OrganizationPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_no_activity"
          component={OrganizationNoActivity}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_eventlog"
          component={OrganizationsEventLogPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_broker_flow"
          component={OrganizationsBrokerFlowPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_email_sms"
          component={OrganizationsEmailSmsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_notification_emails"
          component={OrganizationNotificationEmailsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/org_endpoints"
          component={OrganizationsEndpointsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/testCenter"
          component={TestCenterPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/createSubscriber"
          component={CreateSubscriber}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/activeSubscribers"
          component={ActiveSubscribersPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/editSubscriber"
          component={EditSubscriberPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/sms"
          component={SmsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/reports"
          component={ReportsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/emails"
          component={EmailPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/invoicing"
          component={InvoicingPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/certificateLogs"
          component={CertificateLogsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/adminLogs"
          component={AdminLogsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/user_management"
          component={UserManagementPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/profile"
          component={ProfilePage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/logout"
          component={LoginPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/update_password"
          component={ChangePasswordPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new"
          component={CreateUser}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/edit"
          component={EditUser}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/create_organization"
          component={CreateOrganization}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/audit_report"
          component={AuditReport}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/edit_organization/:id"
          component={EditOrganization}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/conversion_rate_overview"
          component={ConversionRate}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/conversion_rate_banks"
          component={ConversionRateBanksPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/service_settings"
          component={ServiceSettings}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/healthcheck"
          component={HealthcheckPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/healthcheck_edit"
          component={HealthcheckEditPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/kreditz_providers"
          component={KreditzProviders}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/organization_providers"
          component={OrganizationProviders}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/bank_status"
          component={BankStatus}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/banners"
          component={Banners}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/banners_create"
          component={CreateBanner}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/banners_edit/:id"
          component={EditBanners}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/background_jobs"
          component={BackgroundJobsPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/background_jobs_detail/:jobId"
          component={BackgroundJobsDetailPage}
        />

        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/bank_connection_frequency"
          component={BankConnectionFrequency}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/comparison_report"
          component={ComparisonReport}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/certificate_information"
          component={CertificateInformationPage}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/certificate_information_view"
          component={CertificateInfoViewPage}
        />
        <PublicOnlyRoute
          authed={authenticated}
          location={location}
          path="/"
          exact
          component={LoginPage}
        />
        <PublicOnlyRoute
          authed={authenticated}
          location={location}
          path="/login"
          exact
          component={LoginPage}
        />
        <PublicOnlyRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/forget_password"
          component={ForgetPassword}
        />
        <PublicOnlyRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/reset_password"
          component={ResetPassword}
        />
        <PublicOnlyRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new_password"
          component={NewPassword}
        />
        <PublicOnlyRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/new_password_email_validation"
          component={NewPasswordEmailValidation}
        />
        <PrivateRoute
          history={history}
          authed={authenticated}
          location={location}
          path="/verify_tfa"
          component={Authentication}
        />
        <Route component={NoRouteFound} />
      </Switch>
    </ScrollToTop>
  );
};

export default App;